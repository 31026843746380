import { Environment } from '@integration-frontends/apps/common/utils/environment-types';
// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: true,
  bfBaseUrl: 'https://brandfolder.com/api',
  bfWebsiteBaseUrl: 'https://brandfolder.com',
  bfStaticAssetsBaseUrl: 'https://static-fast.brandfolder.com',
  smarOrigin: 'https://smartsheet.com',
  segmentWriteKey: '3D2vwp7cRNdeQQAR0agYzuEH3oAi3XWA',
  environment: Environment.Stage,
};
