import { getParam, sendMessage } from '@integration-frontends/apps/common/utils';

export function emitInitMessage() {
  const initMsg = getParam('initMsg');
  initMsg && sendMessage({ event: 'initMsg', payload: initMsg });
}

export function emitAuthMessage() {
  sendMessage({ event: 'smarPanelAuth' });
}
