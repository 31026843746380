import { BFLabel, BFSearchInput } from '@integration-frontends/common/ui';
import React from 'react';
import { SkuFilterComponentProps } from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/sku-filter/sku-filter-component';
import { FormSection, FormSectionContent } from '../../../common/form';

export function SmarSkuFilterComponent({ searchParams, onChange }: SkuFilterComponentProps) {

  return (
    <FormSection
      expandable={true}
      initialExpanded={searchParams?.skuFilters?.length > 0}
      title="SKU Search"
    >
      <FormSectionContent>
        <BFLabel>Search terms</BFLabel>
        <BFSearchInput
          placeholder="Search terms"
          className="w-full"
          onChange={(e) => onChange(e.target.value)}
        />
      </FormSectionContent>
    </FormSection>
  );
}
