import { createPersistRouteEffect } from '@integration-frontends/apps/common/routing/route-persistence/persist-route-effect';
import { placeAttachmentWorkflow } from '@integration-frontends/apps/common/workflows';
import { init } from '@integration-frontends/common/app';
import { logout } from '@integration-frontends/common/auth/core/application';
import { all } from 'redux-saga/effects';
import { call, put } from 'typed-redux-saga';
import { createPageLoadErrorRedirectEffect } from '../../../../common/src/errors';
import { APP_NAME } from '../app';
import { assetDetailsPageEnteredEffects } from './asset-details-page-entered';
import { attachmentActionClickedWorkflow } from './attachment-action-clicked-workflow';
import { authWorkflow } from './auth-workflow';
import { backToHomeEffects } from './back-to-home';
import { externalLinkClickedEffects } from './external-link-clicked-effects';
import { initWorkflow } from './init-workflow';
import { tokenExpiredWorkflow } from './token-expired-workflow';
import { getObservabilityService } from '@integration-frontends/core';

export function* rootWorkflow() {
  try {
    yield all([
      call(assetDetailsPageEnteredEffects),
      call(attachmentActionClickedWorkflow),
      call(authWorkflow),
      call(backToHomeEffects),
      call(externalLinkClickedEffects),
      call(initWorkflow),
      call(createPersistRouteEffect([/\/select-attachment/])),
      call(placeAttachmentWorkflow),
      call(tokenExpiredWorkflow),
      call(createPageLoadErrorRedirectEffect('/select-attachment')),
    ]);
  } catch (e) {
    yield call(getObservabilityService().addError, e);
    yield all([call(rootWorkflow), put(logout()), put(init({ name: APP_NAME }))]);
  }
}
