import { getParam } from '@integration-frontends/apps/common/utils';
import { loadSegment } from '@integration-frontends/common/analytics';
import { init, selectInitLoading } from '@integration-frontends/common/app';
import { SetApiKey } from '@integration-frontends/common/auth/ui';
import { BFPanel } from '@integration-frontends/common/ui';
import {
  BFHistory,
  BFStore,
  DI_CONTAINER,
  HISTORY_TOKEN,
  STORE_TOKEN,
} from '@integration-frontends/core';
import { IntegrationPageLoader } from '@integration-frontends/integration/ui/common/page-loader/page-loader';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import React, { Suspense, useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import { Navigate, Route, Routes, unstable_HistoryRouter as Router } from 'react-router-dom';
import { dynamicActivate } from '../../../../i18n';
import { AttachmentUploader } from '../../../../libs/integration/ui/src/lib/attachment-uploader';
import { environment } from '../environments/environment';
import './app.scss';
import { HeaderSmartsheet } from './common/ui/src/lib/header-smartsheet';
import { SelectAttachmentSmartsheet } from './common/ui/src/lib/select-attachment-smartsheet';
import { ZeroState } from './common/ui/src/lib/zero-state-smartsheet/zero-state-smartsheet';
import { SDSThemeProvider } from '@smartsheet/lodestar-core';

export const APP_NAME = 'integration-smartsheet';

export enum Size {
  Fixed = 'fixed',
  Full = 'full',
}

function LoadingPage() {
  return (
    <div data-testid="loading-page">
      <IntegrationPageLoader fullHeight={true} />
    </div>
  );
}

function ConnectedApp() {
  const loading = useSelector(selectInitLoading);
  const size = getParam('size') || Size.Full;
  const history = DI_CONTAINER.get<BFHistory>(HISTORY_TOKEN);

  return (
    <Router history={history}>
      <SDSThemeProvider themeKey={'ursa-light'}>
        <BFPanel
          border={false}
          className="flex h-full w-full flex-col overflow-y-hidden"
          style={{
            width: size === Size.Full ? '100%' : 415,
            height: size === Size.Full ? '100vh' : 640,
          }}
        >
          <Suspense fallback={<LoadingPage />}>
            {loading ? (
              <LoadingPage />
            ) : (
              <Routes>
                <Route path="/" />
                <Route
                  path="/getting-started"
                  element={
                    <div data-testid="getting-started-page" id="getting-started-container">
                      <HeaderSmartsheet />
                      <ZeroState />
                    </div>
                  }
                />
                <Route path="/authenticate" element={<SetApiKey />} />
                <Route path="/select-attachment/*" element={<SelectAttachmentSmartsheet />} />
                <Route
                  path="/upload"
                  element={
                    <>
                      <HeaderSmartsheet />
                      <AttachmentUploader />
                    </>
                  }
                />
                <Route path="/loading" element={<LoadingPage />} />
                <Route path="*" element={<Navigate to={'/select-attachment'} replace />} />
              </Routes>
            )}
          </Suspense>
        </BFPanel>
      </SDSThemeProvider>
    </Router>
  );
}

export function App() {
  const store = DI_CONTAINER.get<BFStore>(STORE_TOKEN);

  useEffect(() => {
    store.dispatch(init({ name: APP_NAME }));
    loadSegment(environment);
    dynamicActivate('en');
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <I18nProvider i18n={i18n}>
        <Provider store={store}>
          <ConnectedApp />
        </Provider>
      </I18nProvider>
    </div>
  );
}

export default App;
