import { getParam, sendMessage } from '@integration-frontends/apps/common/utils';
import { sendSegmentIdentifyAction } from '@integration-frontends/common/analytics';
import { init, initLoaded, initLoading, initSuccess } from '@integration-frontends/common/app';
import { getObservabilityService, integrationFrontendsDD } from '@integration-frontends/core';
import { call, delay, put, race, take, takeEvery } from 'typed-redux-saga';
import { environment } from '../../../environments/environment';
import { initPayloadReceived, uploadPayloadReceived } from '../../actions';
import { defaultAuth } from '../common/default-workflow/default-auth';
import { defaultNavigate } from '../common/default-workflow/default-navigate';
import { initPayloadAuth } from '../common/auth/init-payload-auth';
import { initPayloadNavigate } from './init-payload-navigate';

import { subscribeToEvents } from './smartsheet-events';
import { uploadAsset } from './upload-asset';

export const WAIT_FOR_PAYLOAD_DELAY = 2000;

function* handleInitPayloadAction(initPayloadAction: ReturnType<typeof initPayloadReceived>) {
  yield initPayloadAuth(initPayloadAction);
  yield initPayloadNavigate(initPayloadAction);
}

function* handleUploadPayloadAction(uploadPayloadAction: ReturnType<typeof uploadPayloadReceived>) {
  yield uploadAsset(uploadPayloadAction);
}

function* handleInitializationSmartsheetEvents() {
  const [initPayloadAction] = yield race([
    take(initPayloadReceived),
    delay(WAIT_FOR_PAYLOAD_DELAY),
  ]);

  initPayloadAction ? yield initPayloadAuth(initPayloadAction) : yield defaultAuth();

  yield put(initLoading());
  const [uploadPayloadAction] = yield race([
    take(uploadPayloadReceived),
    delay(WAIT_FOR_PAYLOAD_DELAY),
  ]);

  uploadPayloadAction
    ? yield handleUploadPayloadAction(uploadPayloadAction)
    : initPayloadAction
    ? yield initPayloadNavigate(initPayloadAction)
    : yield defaultNavigate();

  yield put(initLoaded());
  yield put(initSuccess());
}

function* handleOngoingSmartsheetEvents() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const [initPayloadAction, uploadPayloadAction] = yield race([
      take(initPayloadReceived),
      take(uploadPayloadReceived),
    ]);

    if (initPayloadAction) {
      yield handleInitPayloadAction(initPayloadAction);
    } else if (uploadPayloadAction) {
      yield handleUploadPayloadAction(uploadPayloadAction);
    }
  }
}

function* handleInit() {
  emitInitMessage();
  subscribeToEvents();
  yield put(sendSegmentIdentifyAction({}));

  yield call(getObservabilityService().initObservability, {
    enableSessionRecordings: true,
    env: environment?.environment,
    datadogCredentials: integrationFrontendsDD,
    service: 'integration-smartsheet',
  });

  yield handleInitializationSmartsheetEvents();
  yield handleOngoingSmartsheetEvents();
}

function emitInitMessage() {
  const initMsg = getParam('initMsg');
  initMsg && sendMessage({ event: 'initMsg', payload: initMsg });
}

export function* initWorkflow() {
  yield takeEvery(init, handleInit);
}
