import { setPersistedRoute } from '@integration-frontends/apps/common/routing/route-persistence';
import { initPayloadReceived } from '@integration-frontends/apps/integration-smartsheet/src/app/actions';
import { sendSegmentIdentifyAction } from '@integration-frontends/common/analytics';
import { initLoaded } from '@integration-frontends/common/app';
import { loginError, loginSuccess } from '@integration-frontends/common/auth/core/application';
import {
  AUTHENTICATE_SERVICE_TOKEN,
  IAuthenticateService,
} from '@integration-frontends/common/auth/core/model';
import { DI_CONTAINER, getObservabilityService } from '@integration-frontends/core';
import { push } from 'redux-first-history';
import { call, put, race, take } from 'typed-redux-saga';
import { environment } from '../../../../environments/environment';
import { IDENTITY_STORE_TOKEN, IIdentityStore } from '../../../common';
import { initPayloadAuth } from '../auth/init-payload-auth';
import { emitAuthMessage } from '../smar';

export function* defaultAuth() {
  const authService: IAuthenticateService = DI_CONTAINER.get(AUTHENTICATE_SERVICE_TOKEN);
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  const identity = yield call(identityStore.get);

  const isAuthed = yield call(authService.isAuthenticated, identity?.token);

  if (isAuthed) {
    yield put(sendSegmentIdentifyAction({ identity }));
    yield put(initLoaded());
    yield call(getObservabilityService().sendIdentity, identity, environment?.environment);
    sendSegmentIdentifyAction({ identity });
    emitAuthMessage();
    return;
  } else {
    yield call(setPersistedRoute, null);
    yield put(push('/getting-started'));
    yield put(initLoaded());

    while (true) {
      // From the /getting-started page, we can either log in with an API key or through Smartsheet (oauth).
      const { loggedInWithApiKey, loggedInThroughSmartsheet } = yield race({
        loggedInWithApiKey: take(loginSuccess),
        error: take(loginError),
        loggedInThroughSmartsheet: take(initPayloadReceived),
      });

      if (loggedInWithApiKey) {
        yield call(identityStore.set, loggedInWithApiKey.payload.identity);
        yield call(getObservabilityService().sendIdentity, identity, environment?.environment);
        yield put(sendSegmentIdentifyAction({ identity: loggedInWithApiKey.payload.identity }));
        emitAuthMessage();
        break;
      } else if (loggedInThroughSmartsheet) {
        yield initPayloadAuth(loggedInThroughSmartsheet, false);
        break;
      } else {
        yield put(push('/getting-started'));
      }
    }
  }
}
