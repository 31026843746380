import { IconViewOnlyEnabled } from '@integration-frontends/common/ui/icons/icon-view-only';
import { useTranslate } from '@integration-frontends/core/translation/use-translate';
import { Tooltip, TooltipBody, useTooltip } from '@smartsheet/lodestar-core';
import React from 'react';

export function SmarIconViewOnly() {
  const { translate } = useTranslate();
  const { tooltipProps, targetProps } = useTooltip({ placement: 'left' });

  return (
    <>
      <Tooltip {...tooltipProps}>
        <TooltipBody>{translate('View only')}</TooltipBody>
      </Tooltip>
      <span {...targetProps}>
        <IconViewOnlyEnabled />
      </span>
    </>
  );
}
