import { sendMessage } from '@integration-frontends/apps/common/utils';
import { sendSegmentAction } from '@integration-frontends/common/analytics';
import { fetchAttachmentsDetails } from '@integration-frontends/integration/core/application/select-attachment/state/effects/common/fetch-attachments-details';
import { AttachmentWithDto } from '@integration-frontends/integration/infrastructure/isomorphic';
import { put, takeEvery } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';
import { attachmentActionClicked } from '../actions';

const handler = function* (action: ReturnType<typeof attachmentActionClicked>) {
  const attachments = action.payload.attachments as AttachmentWithDto[];
  const attachmentDetails = yield call(fetchAttachmentsDetails, attachments);
  const attachmentDtos = attachmentDetails.map((attachment) => {
    return {
      ...attachment.dto,
      ...attachment.dto.attributes,
      cdn_url: attachment.url,
      placementType: action.payload.action,
    };
  });
  yield put(sendSegmentAction({ event: `attachTo${action.payload.action}` }));
  sendMessage({
    event: 'selectedAttachmentForSmartsheet',
    payload: {
      attachments: attachmentDtos,
      placementType: action.payload.action,
    },
  });
};

export function* attachmentActionClickedWorkflow() {
  yield takeEvery(attachmentActionClicked, handler);
}
