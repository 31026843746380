import { AdvancedFiltersProps } from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/advanced-filters-component';
import React, { useEffect } from 'react';
import './smar-advanced-filters.scss';
import noop from 'lodash/noop';
import {
  CustomFieldFiltersContainer,
  OrientationFiltersContainer,
  PrioritizedCustomFieldsFiltersContainer,
  SkuFilterContainer,
  TopFileTypesFiltersContainer,
  TopTagsFiltersContainer,
  UploadDateFilterContainer,
} from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components';
import { SmarTopTagsFiltersComponent } from './components/smar-top-tags-filters/smar-top-tags-filters-component';
import { SmarTopFileTypesComponent } from './components/smar-top-file-types-filters/smar-top-file-types-filters-component';
import { SmarCustomFieldsFiltersComponent } from './components/smar-custom-fields/smar-custom-fields-component';
import { SmarOrientationFiltersComponent } from './components/smar-orientation-filters/smar-orientation-filters-component';
import { FormActions, FormContainer, FormHeader, FormSection } from '../common/form';
import { SmarSkuFilterComponent } from './components/smar-sku-filter/smar-sku-filter-component';
import { SmarUploadDateFilterComponent } from './components/smar-upload-date-filter/smar-upload-date-filter-component';
import { BFButton, ButtonSize } from '@integration-frontends/common/ui';
import { SmarPrioritizedCustomFieldsFiltersComponent } from './components/smar-prioritized-custom-fields/smar-prioritized-custom-fields-component';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { DEFAULT_SORT_OPTIONS } from '@integration-frontends/integration/core/model';

export function SmarAdvancedFilters({
  container,
  containerCustomFields,
  searchParams = {},
  searchParamsChanged = noop,
  sortOptions = DEFAULT_SORT_OPTIONS,
  onClose = noop,
  onApply = noop,
  onClear = noop,
  tags,
  fileTypeAggregates,
  dirty,
}: AdvancedFiltersProps) {

  return (
    <OverlayScrollbarsComponent id="smar-advanced-filters">
      <FormContainer>
        <FormHeader>
          <h2>Advanced filters</h2>
          <a className="link cursor-pointer" onClick={onClose}>
            Hide filters
          </a>
        </FormHeader>

        <PrioritizedCustomFieldsFiltersContainer
          customFields={containerCustomFields}
          searchParams={searchParams}
          onChange={searchParamsChanged}
          Component={SmarPrioritizedCustomFieldsFiltersComponent}
        />

        <FormSection>
          <TopTagsFiltersContainer
            searchParams={searchParams}
            tags={tags}
            onChange={searchParamsChanged}
            Component={SmarTopTagsFiltersComponent}
          />

          <TopFileTypesFiltersContainer
            fileTypeAggregates={fileTypeAggregates}
            searchParams={searchParams}
            onChange={searchParamsChanged}
            Component={SmarTopFileTypesComponent}
          />

          <CustomFieldFiltersContainer
            containerId={container.id}
            searchParams={searchParams}
            onChange={searchParamsChanged}
            Component={SmarCustomFieldsFiltersComponent}
          />
        </FormSection>

        <OrientationFiltersContainer
          searchParams={searchParams}
          onChange={searchParamsChanged}
          Component={SmarOrientationFiltersComponent}
        />

        <SkuFilterContainer
          searchParams={searchParams}
          onChange={searchParamsChanged}
          Component={SmarSkuFilterComponent}
        />

        <UploadDateFilterContainer
          searchParams={searchParams}
          onChange={searchParamsChanged}
          Component={SmarUploadDateFilterComponent}
        />

        <div className="flex-grow" />

        {dirty && (
          <FormActions>
            <a className="link cursor-pointer" onClick={onClear}>
              Clear Filters
            </a>

            <BFButton
              data-testid="apply-btn"
              className="apply-btn"
              size={ButtonSize.Small}
              onClick={() => onApply(searchParams, sortOptions)}
            >
              Apply Filters
            </BFButton>
          </FormActions>
        )}
      </FormContainer>
    </OverlayScrollbarsComponent>
  );
}
