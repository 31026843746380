import { BFStandardRadio } from '@integration-frontends/common/ui';
import { useTranslate } from '@integration-frontends/core/translation/use-translate';
import {
  AttachmentUploadDate,
  getUploadEndDate,
  getUploadStartDate,
} from '@integration-frontends/integration/core/model';
import { UploadDateFilterComponentProps } from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/upload-date-filter/upload-date-filter-component';
import { DatePickerDate, DateRangePickerInput } from '@smartsheet/lodestar-core';
import { format } from 'date-fns';
import React, { useCallback } from 'react';
import { FormSection, FormSectionContent } from '../../../common/form';
import './smar-upload-date-filter-component.scss';

export function SmarUploadDateFilterComponent({
  searchParams,
  onSelect,
  onDateRangeChange,
}: UploadDateFilterComponentProps) {
  const { translate } = useTranslate();

  const uploadStartDate = getUploadStartDate(searchParams);
  const uploadEndDate = getUploadEndDate(searchParams);
  const datePickerUploadStartDate: DatePickerDate = uploadStartDate
    ? {
        year: uploadStartDate.getFullYear(),
        month: uploadStartDate.getMonth(),
        day: uploadStartDate.getDate(),
      }
    : null;
  const datePickerUploadEndDate: DatePickerDate = uploadEndDate
    ? {
        year: uploadEndDate.getFullYear(),
        month: uploadEndDate.getMonth(),
        day: uploadEndDate.getDate(),
      }
    : null;

  function isChecked(attachmentUploadDate: AttachmentUploadDate): boolean {
    return searchParams?.uploadDateFilter?.uploadDateEnum === attachmentUploadDate || false;
  }

  const dateRangeChange = useCallback(
    (start: DatePickerDate, end: DatePickerDate) => {
      onDateRangeChange(
        format(new Date(start.year, start.month, start.day), 'yyyy-mm-dd'),
        format(new Date(end.year, end.month, end.day), 'yyyy-mm-dd'),
      );
    },
    [onDateRangeChange],
  );

  return (
    <FormSection
      className="smar-upload-date-filter-container"
      expandable={true}
      initialExpanded={!!searchParams?.uploadDateFilter}
      title={translate('Upload Date')}
    >
      <FormSectionContent className="upload-date-filter-content">
        <div className="flex items-center">
          <BFStandardRadio
            checked={!searchParams?.uploadDateFilter}
            onChange={() => onSelect(null)}
            className="mr-xs"
            name="upload-date-filter"
            value="upload-date-filter"
            label={translate('All')}
          />
        </div>
        <div className="flex items-center">
          <BFStandardRadio
            value={AttachmentUploadDate.Last30Minutes}
            checked={isChecked(AttachmentUploadDate.Last30Minutes)}
            onChange={() => onSelect(AttachmentUploadDate.Last30Minutes)}
            className="mr-xs"
            name="upload-date-filter"
            label={translate('Last 30 minutes')}
          />
        </div>
        <div className="flex items-center">
          <BFStandardRadio
            value={AttachmentUploadDate.Past24Hours}
            checked={isChecked(AttachmentUploadDate.Past24Hours)}
            onChange={() => onSelect(AttachmentUploadDate.Past24Hours)}
            className="mr-xs"
            name="upload-date-filter"
            label={translate('Past 24 hours')}
          />
        </div>
        <div className="flex items-center">
          <BFStandardRadio
            value={AttachmentUploadDate.Past7Days}
            checked={isChecked(AttachmentUploadDate.Past7Days)}
            onChange={() => onSelect(AttachmentUploadDate.Past7Days)}
            className="mr-xs"
            name="upload-date-filter"
            label={translate('Past 7 days')}
          />
        </div>
        <div className="date-range-upload-container">
          <BFStandardRadio
            value={AttachmentUploadDate.DateRange}
            checked={isChecked(AttachmentUploadDate.DateRange)}
            onChange={() => onSelect(AttachmentUploadDate.DateRange)}
            className="mr-xs"
            name="upload-date-filter"
            label={translate('Date range')}
          />
          {isChecked(AttachmentUploadDate.DateRange) && (
            <DateRangePickerInput
              endValue={
                !isNaN(datePickerUploadEndDate.day) &&
                !isNaN(datePickerUploadEndDate.month) &&
                !isNaN(datePickerUploadEndDate.year) &&
                datePickerUploadEndDate
              }
              locale={'en'}
              onChange={dateRangeChange}
              startValue={
                !isNaN(datePickerUploadStartDate.day) &&
                !isNaN(datePickerUploadStartDate.month) &&
                !isNaN(datePickerUploadStartDate.year) &&
                datePickerUploadStartDate
              }
              format={'mm/dd/yyyy'}
            />
          )}
        </div>
      </FormSectionContent>
    </FormSection>
  );
}
