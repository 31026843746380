import { DI_CONTAINER, STORE_TOKEN } from '@integration-frontends/core';
import { initPayloadReceived, uploadPayloadReceived } from '../../actions';
import {
  SmarPanelInitPayload,
  SmarPanelUploadPayload,
  SmartsheetEventType,
  subscribeToEvent,
} from '../../common/smartsheet';

export function subscribeToEvents() {
  const store = DI_CONTAINER.get(STORE_TOKEN);

  window.addEventListener(
    'message',
    async (message: { data: { event: string; payload: SmarPanelInitPayload } }) => {
      const { event, payload } = message.data;
      if (event === 'smarPanelInit') {
        console.log('Received SMAR init payload');
        console.log(payload);
        store.dispatch(initPayloadReceived({ initPayload: payload }));
      }
    },
  );

  window.addEventListener(
    'message',
    async (message: { data: { event: string; payload: SmarPanelUploadPayload[] } }) => {
      const { event, payload } = message.data;
      if (event === 'smarPanelUploadAsset') {
        console.log('Received SMAR asset upload');
        console.log(payload);
        store.dispatch(uploadPayloadReceived({ uploadPayload: payload }));
      }
    },
  );

  subscribeToEvent(SmartsheetEventType.AttachmentReceived, (payload) => {
    console.log('Received SMAR attachment');
    console.log(payload);
  });
}
