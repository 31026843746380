import { setPersistedRoute } from '@integration-frontends/apps/common/routing/route-persistence';
import { sendMessage } from '@integration-frontends/apps/common/utils';
import { initSuccess } from '@integration-frontends/common/app';
import { logout } from '@integration-frontends/common/auth/core/application';
import { DI_CONTAINER, getObservabilityService } from '@integration-frontends/core';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { IDENTITY_STORE_TOKEN, IIdentityStore } from '../common';
import { defaultWorkflow } from './common/default-workflow';

function* onLogout() {
  yield call(getObservabilityService().stopSessionRecording);
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  sendMessage({
    event: 'smarPanelLogout',
  });
  yield call(setPersistedRoute, null);
  yield call(identityStore.set, null);
  yield put(initSuccess());
  yield defaultWorkflow();
}

export function* authWorkflow() {
  yield all([takeEvery(logout.type, onLogout)]);
}
