import { AttachmentActionContainerBaseProps } from '@integration-frontends/integration/ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assetEntitySelectors, IntegrationRootState } from '@integration-frontends/integration/core/application';
import { attachmentActionClicked } from '../../../actions';
import { createNotification } from '@integration-frontends/common/notifications';
import { SmartsheetAttachmentAction } from '../../../model';
import {
  SmartsheetEventType,
  SmartsheetPostConfirmationPayload,
  subscribeToEvent,
} from '../../../common/smartsheet';
import { reactToPlacement } from '../smar-attachment-placement-details/smar-attachment-placement-details';
import { ATTACH_CONFIRMATION_TIMEOUT_MS, Availability } from './constants';
import noop from 'lodash/noop';
import { IconAttachToRow } from '@integration-frontends/common/ui';

export function AttachToRowActionContainer({
  selectedAttachments,
  render,
  onLoading = noop,
  onSuccess = noop,
  isMultiSelect = false,
  location = 'show-page',
}: AttachmentActionContainerBaseProps) {
  const dispatch = useDispatch();
  const id = 'ATTACH_TO_ROW';
  const [label, setLabel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmationPayload, setConfirmationPayload] =
    useState<SmartsheetPostConfirmationPayload>(null);
  const success = !!confirmationPayload;
  const Icon = IconAttachToRow;
  const asset = useSelector((state: IntegrationRootState) =>
    assetEntitySelectors.selectById(state, selectedAttachments[0]?.assetId),
  );

  function initialize() {
    setLoading(false);
    setConfirmationPayload(null);
  }

  useEffect(() => initialize(), []);

  useEffect(() => {
    if (loading) {
      setLabel(getLoadingLabel());
    } else if (confirmationPayload) {
      setLabel(getSuccessLabel());
    } else {
      setLabel(getDefaultLabel());
    }
  }, [loading, selectedAttachments, confirmationPayload]);

  useEffect(() => {
    if (success) {
      setLoading(false);
      onSuccess(getSuccessLabel());
      const timeout = setTimeout(initialize, ATTACH_CONFIRMATION_TIMEOUT_MS);
      return () => clearTimeout(timeout);
    }
  }, [success]);

  const onClick = useCallback(() => {
    setLoading(true);
    onLoading(getLoadingLabel());

    dispatch(
      attachmentActionClicked({
        action: SmartsheetAttachmentAction.PlaceToRow,
        attachments: selectedAttachments,
      }),
    );

    subscribeToEvent(SmartsheetEventType.PostConfirmation, (payload, unsubscribe) => {
      setConfirmationPayload(payload);
      selectedAttachments.forEach((attachment) => {
        reactToPlacement(dispatch, attachment.id, attachment.assetId);
      });
      sendToastNotification(payload);
      unsubscribe();
    });
  }, [selectedAttachments]);

  function sendToastNotification(payload): void {
    const message = selectedAttachments?.length > 1 ? `${selectedAttachments?.length} files have been attached to row ${payload?.smarRowNumber}` : `1 file has been attached to row ${payload?.smarRowNumber}`;
    dispatch(createNotification({message, location}));
  }

  function getAttachmentCountLabel(count: number, singularLabel: string, pluralLabel: string): string {
    return `${count} ${count === 1 ? singularLabel : pluralLabel}`;
  }
  
  function getLoadingLabel(): string {
    const attachmentCount = selectedAttachments?.length || 0;
    const label = getAttachmentCountLabel(attachmentCount, 'file', 'files');
    return isMultiSelect ? `Attaching ${label}...` : 'Attaching file...';
  }
  
  function getSuccessLabel(): string {
    const attachmentCount = selectedAttachments?.length || 0;
    const rowNumber = confirmationPayload?.smarRowNumber || '';
    const label = getAttachmentCountLabel(attachmentCount, 'file', 'files');
    return isMultiSelect ? `${label} attached to row ${rowNumber}!` : `Attached to row ${rowNumber}!`;
  }
  
  function getDefaultLabel(): string {
    const attachmentCount = selectedAttachments?.length || 0;
    const label = getAttachmentCountLabel(attachmentCount, 'file', 'files');
    return isMultiSelect ? `Attach ${label} to row` : 'Attach file to row';
  }
  return render({
    id,
    Icon,
    label,
    loading,
    success: !!confirmationPayload,
    enabled: (asset?.availability === Availability.Published),
    onClick,
    ariaLabel: `Attach to row ${selectedAttachments?.length}`,
  });
}
