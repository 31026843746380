import { OrientationFiltersComponentProps } from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/orientation-filters/orientation-filters-component';
import { AttachmentOrientation } from '@integration-frontends/integration/core/model';
import { BFStandardCheckbox } from '@integration-frontends/common/ui';
import React from 'react';
import { FormSection, FormSectionContent } from '../../../common/form';

export function SmarOrientationFiltersComponent({
  searchParams,
  onChange,
}: OrientationFiltersComponentProps) {
  function isChecked(orientation: AttachmentOrientation): boolean {
    return searchParams?.orientationFilters?.includes(orientation) || false;
  }

  return (
    <FormSection expandable={true} initialExpanded={false} title="Orientation">
      <FormSectionContent>
        <div className="flex flex-col">
          <div className="flex justify-between">
            <BFStandardCheckbox
              checked={isChecked(AttachmentOrientation.Horizontal)}
              onChange={(e) => {
                onChange(AttachmentOrientation.Horizontal, e.target.checked);
              }}
              label="Horizontal"
            />
          </div>

          <div className="flex justify-between">
            <BFStandardCheckbox
              checked={isChecked(AttachmentOrientation.Vertical)}
              onChange={(e) => onChange(AttachmentOrientation.Vertical, e.target.checked)}
              label="Vertical"
            />
          </div>

          <div className="flex justify-between">
            <BFStandardCheckbox
              checked={isChecked(AttachmentOrientation.Square)}
              onChange={(e) => onChange(AttachmentOrientation.Square, e.target.checked)}
              label="Square"
            />
          </div>

          <div className="flex justify-between">
            <BFStandardCheckbox
              checked={isChecked(AttachmentOrientation.Panoramic)}
              onChange={(e) => onChange(AttachmentOrientation.Panoramic, e.target.checked)}
              label="Panoramic"
            />
          </div>
        </div>
      </FormSectionContent>
    </FormSection>
  );
}
