import { sendMessage } from '@integration-frontends/apps/common/utils';

/**
 * Smartsheet messages
 */

export interface SmarPanelInitPayload {
  locale?: string;
  attachmentId?: string;
  accessToken: string;
}

export interface SmarRowData {
  [key: string]: string;
}
export interface SmarPanelUploadPayload {
  file_name: string;
  file: Blob;
  smarRowId: number;
  smarSheetId: number;
  smarRowData: SmarRowData;
}

export interface SmartsheetPostConfirmationPayload {
  status: 'Confirmation';
  smarRowId: string;
  smarRowNumber: string;
  smarSheetId: string;
  smarSheetName: string;
}

export interface SmartsheetGetAttachmentPayload {
  assetID: string;
  attachmentID: string;
}

export interface SmartsheetAttachmentReceivedPayload {
  RowId: string;
  RowNum: number;
  RowName: string;
  SheetID: string;
  SheetName: string;
}

export interface SmartsheetAttachmentDetailsPayload {
  AttachmentID: string;
  AssetID: string;
  RowID: string;
  RowNum: number;
  RowName: string;
  SheetID: string;
  SheetName: string;
}

export enum SmartsheetEventType {
  AttachmentReceived = 'getSmarAttachment',
  PostConfirmation = 'smarPanelPost',
  PanelInitPayload = 'smarPanelInit',
  PanelUploadPayload = 'smarPanelUploadAsset',
  AttachmentDetails = 'smarPanelAttachment',
}

export enum SmartsheetCommandType {
  GetAttachment = 'smarPanelAttachment',
  GetPlacedAttachmentDetails = 'getSmarAttachment',
}

export type SmartsheetEventPayload<T = SmartsheetEventType> =
  T extends SmartsheetEventType.PostConfirmation
    ? SmartsheetPostConfirmationPayload
    : T extends SmartsheetEventType.PanelUploadPayload
    ? SmarPanelUploadPayload
    : T extends SmartsheetEventType.PanelInitPayload
    ? SmarPanelInitPayload
    : T extends SmartsheetEventType.AttachmentReceived
    ? SmartsheetAttachmentReceivedPayload
    : SmartsheetAttachmentDetailsPayload;

export type SmartsheetCommandPayload<T = SmartsheetCommandType> = SmartsheetGetAttachmentPayload;

export function subscribeToEvent<T extends SmartsheetEventType>(
  eventType: T,
  callback: (payload: SmartsheetEventPayload<T>, unsubscribe: () => void) => void,
) {
  const handler = (message: { data: { event: string; payload: SmartsheetEventPayload<T> } }) => {
    if (message.data.event === eventType) {
      console.log(`Received SMAR event ${message.data.event}`);
      console.log(message.data.payload, 'Payload');

      callback(message.data.payload, () => {
        window.removeEventListener('message', handler);
      });
    }
  };

  window.addEventListener('message', handler);
}

export function sendCommand<T extends SmartsheetCommandType>(
  commandType: T,
  payload: SmartsheetCommandPayload<T>,
) {
  sendMessage({ event: commandType, payload });
}

/**
 * Panel messages
 */
export enum PanelEventType {
  AccessTokenExpired = 'smarAccessTokenExpired',
  OAuth = 'smarPaneloAuth',
}

export function sendPanelEvent<T extends PanelEventType>(commandType: T) {
  sendMessage({ event: commandType });
}
