import { sendMessage } from '@integration-frontends/apps/common/utils';
import {
  BFIconButton,
  IconClose,
  IconSize,
  VerticalDividerLine,
} from '@integration-frontends/common/ui';
import React, { ReactElement } from 'react';
import './panel-header-smartsheet.scss';

export interface PanelHeaderProps {
  actions: ReactElement[];
}

const panelHeaderHidden = document.URL.indexOf('hideHeader=true') !== -1;

export function PanelHeaderSmartsheet({ actions }: PanelHeaderProps) {
  return (
    !panelHeaderHidden && (
      <div
        className="px-lg panel-header-container flex items-center justify-between"
        id="panel-header-container"
      >
        <div>
          <h1 className="brandfolder-header-text">Brandfolder</h1>
        </div>
        <div className="flex">
          {actions.map((action, idx) => (
            <div
              key={idx}
              className="ml-2 flex flex cursor-pointer items-center text-6xl text-black"
            >
              {action}
            </div>
          ))}
          <VerticalDividerLine height={28} />
          <BFIconButton
            className="mt-xxs flex cursor-pointer"
            id="smar-header-close"
            onClick={() => sendMessage({ event: 'smarPanelClose' })}
            iconElement={<IconClose iconSize={IconSize.Large} />}
            style={{
              background: 'none',
              margin: 0,
            }}
          />
        </div>
      </div>
    )
  );
}
