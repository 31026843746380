import { BFLabel, BFSelect } from '@integration-frontends/common/ui';
import React from 'react';
import { PrioritizedCustomFieldsFiltersComponentProps } from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/prioritized-custom-fields-filters/prioritized-custom-fields-filters-component';
import {
  FormGroupVertical,
  FormRowVertical,
  FormSection,
  FormSectionContent,
} from '../../../common/form';
import { PrioritizedCustomFieldsFiltersSelectComponentProps } from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/prioritized-custom-fields-filters/prioritized-custom-fields-filters-select-component';
import { PrioritizedCustomFieldsFiltersSelectContainer } from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/prioritized-custom-fields-filters/prioritized-custom-fields-filters-select-container';

export function SmarPrioritizedCustomFieldsFiltersComponent({
  customFields,
  searchParams,
  onChange,
}: PrioritizedCustomFieldsFiltersComponentProps) {
  const prioritizedCustomFields = customFields.filter((field) => field.prioritized);

  if (prioritizedCustomFields.length === 0) return null;

  return (
    <FormSection>
      <FormSectionContent>
        <FormGroupVertical>
          {prioritizedCustomFields.map((field) => (
            <PrioritizedCustomFieldsFiltersSelectContainer
              key={field?.id}
              customField={field}
              searchParams={searchParams}
              onChange={onChange}
              Component={SmarPrioritizedCustomFieldsFiltersSelectComponent}
            />
          ))}
        </FormGroupVertical>
      </FormSectionContent>
    </FormSection>
  );
}

const SmarPrioritizedCustomFieldsFiltersSelectComponent = ({
  customField,
  customFieldValues,
  searchParams,
  onChange,
}: PrioritizedCustomFieldsFiltersSelectComponentProps) => {
  return (
    <FormRowVertical key={customField.id}>
      <BFLabel>{customField.name}</BFLabel>
      <BFSelect
        required
        placeholder="Select Value"
        data-testid="prioritized-custom-fields-filter-select"
        options={customFieldValues.map((option) => ({ label: option.value, value: option.value }))}
        value={searchParams?.customFieldFilters?.[customField.id]?.value || ''}
        onOptionChange={(option) => onChange(customField, option?.value as string)}
        className="text-primary text-base"
      />
    </FormRowVertical>
  );
};
