import { TopFileTypesFiltersComponentProps } from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/top-file-type/top-file-types-filters-component';
import { FormSectionContent, FormSubSection } from '../../../common/form';
import { BFStandardCheckbox } from '@integration-frontends/common/ui';
import {
  CheckboxListContainer,
  CheckboxListItemContainer,
} from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/common';
import { hasFileTypeFilter } from '@integration-frontends/integration/core/model';
import React from 'react';
import '../../smar-advanced-filters.scss';
import { orderBy } from 'lodash';

export function SmarTopFileTypesComponent({
  fileTypeAggregates,
  fileTypeToggle,
  searchParams,
}: TopFileTypesFiltersComponentProps) {

  const sortedFileTypeAggregates = orderBy(fileTypeAggregates, ['name']);

  return (
    <FormSubSection
      id="top-file-types-filters"
      data-testid="top-file-types-filters"
      title="Top File Types"
    >
      <FormSectionContent>
        <CheckboxListContainer className="checkbox-list">
          {sortedFileTypeAggregates.slice(0, 10).map((fileType) => (
            <CheckboxListItemContainer key={fileType.name}>
              <div
                data-testid="file-type-filter"
                className="flex cursor-pointer"
                onClick={() => fileTypeToggle(fileType.name)}
              >
                <div className="list-item min-w-0">
                  <BFStandardCheckbox
                    value={fileType.name}
                    checked={hasFileTypeFilter(searchParams, fileType.name)}
                    label={fileType.name}
                    readOnly
                  />
                </div>
              </div>
            </CheckboxListItemContainer>
          ))}
        </CheckboxListContainer>
      </FormSectionContent>
    </FormSubSection>
  );
}
