import { Disclosure } from '@headlessui/react';
import {
  PanelEventType,
  sendPanelEvent,
} from '@integration-frontends/apps/integration-smartsheet/src/app/common/smartsheet';
import {
  loginThunk,
  selectError,
  selectIdentity,
} from '@integration-frontends/common/auth/core/application';
import {
  BFButton,
  BFErrorLabel,
  BFInput,
  BFLabel,
  BrandfolderBySmartsheetLogo,
  ButtonType,
} from '@integration-frontends/common/ui';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VideoLoader } from '../smar-video/video-loader';
import './zero-state-smartsheet.scss';

export function ZeroState() {
  const dispatch = useDispatch();
  const identity = useSelector(selectIdentity);
  const error = useSelector(selectError);
  const [localApiKey, setLocalApiKey] = useState<string>(null);
  const [displayIsColumn, setDisplayIsColumn] = useState<boolean>(window.innerWidth < 920);

  function getApiKey(): string {
    return localApiKey || identity?.token || '';
  }

  function submit() {
    dispatch(loginThunk(getApiKey()) as any);
    setLocalApiKey(null);
  }

  const connectBrandfolder = () => {
    sendPanelEvent(PanelEventType.OAuth);
  };

  const panelHeaderContainerHeight =
    document.getElementById('panel-header-container')?.offsetHeight;

  const scrollToBottom = () => {
    setTimeout(() => {
      const apiKeyLoginElement = document.getElementById('api-key-login-container');
      apiKeyLoginElement.scrollTop = apiKeyLoginElement.scrollHeight;
    }, 250);
  };

  window.addEventListener('resize', function () {
    if (window.innerWidth >= 920) {
      setDisplayIsColumn(false);
    } else {
      setDisplayIsColumn(true);
    }
  });

  const apiKeyLogin = () => {
    return (
      <span
        className="api-key-login-container"
        id="api-key-login-container"
        style={{ marginBottom: '20px' }}
      >
        <Disclosure>
          <Disclosure.Button data-testid="sign-in-toggle" className="sign-in-toggle font-medium">
            Prefer to log in using an API key?
          </Disclosure.Button>
          <Disclosure.Panel className="api-key-disclosure-panel gap-sm flex w-full flex-col">
            <div className="api-key-input-container">
              <div className="api-key-label">
                <BFLabel htmlFor="brandfolder-api-key">API key</BFLabel>
                <a
                  className="click-here-link font-medium"
                  href="https://brandfolder.com/profile#integrations"
                  target="_blank"
                >
                  Find your API key here
                </a>
              </div>
              <BFInput
                id="brandfolder-api-key"
                type="text"
                placeholder="Enter your Brandfolder API key"
                value={getApiKey()}
                onChange={(e) => setLocalApiKey(e.target.value)}
              />
              {error && <BFErrorLabel data-cy="error">{error.message}</BFErrorLabel>}
            </div>
            <BFButton className="sign-in-btn w-full" data-cy="save" onClick={submit}>
              Sign in
            </BFButton>
          </Disclosure.Panel>
        </Disclosure>
      </span>
    );
  };

  return (
    <div
      className="zero-state-container"
      style={{ height: `calc(100% - ${panelHeaderContainerHeight}px)` }}
    >
      <div className="zero-state-content-container">
        <span className="zero-state-content">
          <BrandfolderBySmartsheetLogo
            className="bf-smar-logo"
            style={{ height: '50px', width: '162px' }}
          />
          <div className="connect-to-bf-btn-container">
            <BFButton
              aria-label={'Connect your Brandfolder'}
              data-testid="connect-to-brandfolder-btn"
              className="connect-to-bf-btn "
              buttonType={ButtonType.Primary}
              onClick={connectBrandfolder}
            >
              Connect your Brandfolder
            </BFButton>
          </div>
          <div className="create-bf-cta-wrapper">
            <p>Don't have a Brandfolder account?</p>
            <span className="click-here-span">
              <p className="click-here-text">Click</p>{' '}
              <a
                className="click-here-link font-medium"
                target="_blank"
                href="https://brandfolder.com/product/smartsheet-and-brandfolder/?utm_source=smartsheet&utm_medium=product-bf-panel&utm_campaign=exp"
              >
                {' '}
                here{' '}
              </a>
              <p className="click-here-text">to learn more.</p>
            </span>
          </div>
          {!displayIsColumn && apiKeyLogin()}
        </span>
        <div className="divider-line"></div>
        <span className="zero-state-content">
          <p className="brandfolder-description">
            <span className="description-header">What is Brandfolder?</span>
            <br />
            <br />
            Brandfolder is an award winning Digital Asset Management platform to easily store,
            manage, share, manipulate, and analyze all your files across hundreds of formats
            including 8K video, documents, images, and 3D renderings.
          </p>
          <VideoLoader
            controls={true}
            preload="none"
            size="full"
            videoMaxWidth={380}
            videoSrc="https://stream.mux.com/So2EuxEBmOS0244xgQwuQgxA02DLIas01Dr.m3u8"
          ></VideoLoader>
          {displayIsColumn && apiKeyLogin()}
        </span>
      </div>
    </div>
  );
}
